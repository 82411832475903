import { computed, defineComponent, nextTick, onMounted, ref, shallowRef } from "vue";
import { CommonCalendarLayoutData, SchedulerControlsElement, SchedulerControlsSlots, RootCalendarLayoutElement, RootCalendarLayoutElementSlots } from "./RootCalendarLayoutElement";
import { CalendarGridElement, CalendarGridElementSlots, treeMaxWidth } from "./CalendarGridElement";
import { GameCalendarUiElement, k_dayGroupKeyFormat } from "./GameScheduler.shared";
import { Competition, Datelike, Division, FieldUID, Guid, Integerlike, Season } from "src/interfaces/InleagueApiV1";
import { Field } from "src/composables/InleagueApiV1";
import { Client } from "src/store/Client";
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { arrayFindOrFail, assertNonNull, assertTruthy, copyViaJsonRoundTrip, exhaustiveCaseGuard, forceCheckedIndexedAccess, max, min, nextGlobalIntlike, noAvailableOptions, parseIntOrFail, requireNonNull, sortBy, sortByDayJS, UiOptions } from "src/helpers/utils";

import * as cal from "./CalendarLayout"
import dayjs from "dayjs";
import { AssignCoachesStore, CreatePracticeSlotsFormStore, MutUiSelection, practiceCentricSeasonCurrentWeek, PracticeSchedulerActions, PracticeSchedulerActionTabID, SlotAssignmentUserBinding, SlotSchedulingDataStore } from "./PracticeSchedulerActions";
import { CalendarElementStyle, calendarElementStylingDefault } from "./CalendarElementStyle";
import { getCompetitionsOrFail } from "src/store/Competitions";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { DAYJS_FORMAT_HTML_DATE } from "src/helpers/formatDate";
import { createPracticeSlotAssignments, createPracticeSlots, deletePracticeSlots, deletePracticeSlotAssignment, getCreatePracticeSlotsFormData, getPracticeSlotAssignmentData1, listPracticeSlots, PracticeSlot, PracticeSlotAssignment, unlinkPracticeSlot, linkPracticeSlot, updatePracticeSlotDivisions, PracticeSchedulerSeason, getSeasonsForPracticeScheduler, updatePracticeSlot } from "./PracticeScheduler.io";
import { forAllCalendarNodes, NodeSourceTree, SequentialDates } from "./GameLayoutTreeStore";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import iziToast from "izitoast";
import { ExtractFormSlots } from "./PracticeScheduler.form";
import { User } from "src/store/User";
import { AutoModal, DefaultModalController_r, DefaultTinySoccerballBusyOverlay, useDefaultNoCloseModalIfBusy } from "src/components/UserInterface/Modal";
import { ConfirmPracticeSlotAssignmentsModal, ConfirmPracticeSlotAssignmentDeleteModal, PracticeSlotDetailModal, PracticeSchedulerCalendarBodyElement, PracticeSchedulerLayoutNode, PracticeSchedulerUiElement, PracticeSchedulerViewOptions, PracticeSlotEx, PracticeSchedulerAdminModalEvent } from "./PracticeScheduler.elems";
import { AuthZ_practiceSchedulerSelfScheduler, AuthZ_practiceSchedulerSuperUser } from "./PracticeScheduler.route";
import { SoccerBall } from "src/components/SVGs";
import { routeRootScrollContainerRef, routeRootScrollPos } from "src/router/RouterScrollBehavior";
import { PracticeSlotsAssignmentsReportStore } from "./PracticeScheduler.report";
import { PracticeSchedulerPermitsMgrStore } from "./PracticeSchedulerPermitsMgr";
import { faUndo } from "@fortawesome/pro-solid-svg-icons";
import { Btn2 } from "src/components/UserInterface/Btn2";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { freshNoToastLoggedInAxiosInstance } from "src/boot/axios";

/**
 * common type param for LayoutNode<>
 */
type NodeT = PracticeSchedulerUiElement

export default defineComponent({
  setup() {
    const ready = ref(false)

    const allSeasons = ref<PracticeSchedulerSeason[]>([])
    const allCompetitions = ref<Competition[]>([])
    const allDivisions = ref<Division[]>([])
    const allFields = ref<Field[]>([])
    const coreCompSeasonUID = computed<Guid | null>(() => allCompetitions.value.find(v => v.competitionID /*weakEq*/ == 1)?.seasonUID ?? null)
    const assignCoachesStore = AssignCoachesStore()

    type ManageMode = "self" | "admin"

    const authZ_practiceSchedulerSuperUser = computed(() => AuthZ_practiceSchedulerSuperUser())
    const authZ_practiceSchedulerSelfAssign = computed(() => AuthZ_practiceSchedulerSelfScheduler())
    const manageMode = ref<ManageMode>(authZ_practiceSchedulerSuperUser.value ? "admin" : "self")

    const undoStack = ref<Undoable[]>([])
    const popAndRunMostRecentUndoable = async () : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const undoable = undoStack.value.pop()
        if (!undoable) {
          return
        }

        switch (undoable.type) {
          case "create": {
            await deletePracticeSlots(axiosAuthBackgroundInstance, {practiceSlotIDs: undoable.practiceSlotIDs})
            break
          }
          default: exhaustiveCaseGuard(undoable.type)
        }

        await naiveDefaultFullReload()
      })
    }

    const treeMgr = (() => {
      // TODO: use Field object as field key instead of FieldUID
      const tree = ref(new Map<Datelike, Map<FieldUID, cal.LayoutNodeRoot<NodeT>>>())
      const list = ref<PracticeSlotEx[]>([])

      const __vueKey = ref(nextGlobalIntlike())

      const totalFieldRenderCount = computed(() => {
        let v = 0
        for (const [_, fields] of tree.value.entries()) {
          v += fields.size
        }
        return v;
      })

      // does some side-effects as part of resolving
      // investigate: purer version of this (less or no side-effects)
      const sideEffecting_slotResolver = ReactiveReifiedPromise<{noSlotsFound: boolean}>(undefined, {defaultDebounce_ms: 250})

      const reload = async (args: {
        seasonUID: Guid,
        fieldUIDs: Guid[],
        divID: undefined | Guid,
        dateFrom: Datelike,
        dateTo: Datelike,
        onlyShowSelectedDatesAndFieldsHavingContent: boolean,
      }) : Promise<void> => {
        const entityLookup = {
          divs: new Map(allDivisions.value.map(v => [v.divID, v])),
          fields: new Map(allFields.value.map(v => [v.fieldUID, v])),
          seasons: new Map(allSeasons.value.map(v => [v.seasonUID, v]))
        };

        await sideEffecting_slotResolver.run(async () => {
          const slots = await listPracticeSlots(axiosAuthBackgroundInstance, {
            seasonUID: args.seasonUID,
            dateRange: {
              start: args.dateFrom,
              end: args.dateTo,
            },
            divID: args.divID,
            fieldUIDs: args.fieldUIDs,
            includeDeleted: false,
            expand: authZ_practiceSchedulerSuperUser.value
              ? ["activeAssignments"]
              : undefined,
          })

          const slotExs = slots.map(slot => PracticeSlotEx(slot, entityLookup))
          const fakeRoot : cal.LayoutNodeRoot<any> = {parent: null, children: []}
          const nodes = slotExs.map(slotEx => PracticeSchedulerLayoutNode(fakeRoot, PracticeSchedulerUiElement(slotEx)))

          tree.value = NodeSourceTree(
            SequentialDates(args.dateFrom, args.dateTo).map(v => v.format(k_dayGroupKeyFormat)),
            allFields.value,
            nodes,
            args.onlyShowSelectedDatesAndFieldsHavingContent
              ? (_date, _field, elems) => elems.length > 0 ? "retain" : "trim"
              : null
          )

          list.value = slotExs

          __vueKey.value = nextGlobalIntlike()

          return {
            noSlotsFound: slots.length === 0
          }
        }).getResolvedOrFail()
      }

      const layoutStrategy = computed(() => {
        const layoutStrategyPerDatePerField = [...tree.value.entries()].map(([datelike, fieldMapping]) => {
          const layoutStrategyPerField = [...fieldMapping.entries()].map(([fieldUID, root]) => {
            return [fieldUID, {method: "overlapOK" as const, forestMaxWidth: treeMaxWidth(root)}] as const
          })
          return [datelike, new Map(layoutStrategyPerField)] as const
        })

        const xm = new Map(layoutStrategyPerDatePerField)

        return {
          getOrFail(date: Datelike, fieldUID: Guid) {
            return requireNonNull(xm.get(date)?.get(fieldUID))
          }
        }
      })

      return {
        get __vueKey() { return __vueKey.value },
        get byDateByField() { return tree.value },
        get listView() : readonly PracticeSlotEx[] { return list.value },
        get totalFieldRenderCount() { return totalFieldRenderCount.value },
        get layoutStrategy() { return layoutStrategy.value },
        reload,
        reset: () => {
          sideEffecting_slotResolver.reset(),
          tree.value = new Map()
        },
        get slotResolver() { return sideEffecting_slotResolver.underlying },
        get isEmpty() { return sideEffecting_slotResolver.underlying.status === "resolved" && sideEffecting_slotResolver.underlying.data.noSlotsFound},
        findNodeByPracticeSlotID(practiceSlotID: number) : PracticeSlotEx | null {
          let result : PracticeSlotEx | null = null;

          forAllCalendarNodes(tree.value, node => {
            if (node.data.practiceSlotID === practiceSlotID) {
              result = node.data
              return "bail"
            }
            return "continue"
          })

          return result
        }
      }
    })()

    const actionState = (() => {
      const selectedTabID = ref(PracticeSchedulerActionTabID.createPracticeSlots)

      /**
       * null means both "not loaded yet" and "no authZ to create practice slots"
       * we don't currently need to distinguish between those 2 states
       */
      const createPracticeSlotsForm = shallowRef<CreatePracticeSlotsFormStore | null>(null)
      const slotSchedulingData = shallowRef<SlotSchedulingDataStore | null>(null)
      const reportingData = shallowRef<PracticeSlotsAssignmentsReportStore | null>(null)
      const practiceSchedulerPermitsMgrStore = shallowRef<PracticeSchedulerPermitsMgrStore | null>(null)

      const initCreatePracticeSlotsFormAndSlotSchedulingData = async (args: {initialSeasonUID: Guid}) : Promise<void> => {
        const data = await getCreatePracticeSlotsFormData(axiosAuthBackgroundInstance)

        if (!data.authZ) {
          createPracticeSlotsForm.value = null
        }
        else {
          createPracticeSlotsForm.value = CreatePracticeSlotsFormStore({
            seasons: data.seasons,
            divisions: data.divisions,
            fields: data.fields,
          })

          if (createPracticeSlotsForm.value.seasonOptions.options.find(opt => opt.value === args.initialSeasonUID)) {
            createPracticeSlotsForm.value.seasonUID.selectedKey = args.initialSeasonUID
            createPracticeSlotsForm.value.recomputeRepeatWeeksOptions(createPracticeSlotsForm.value.seasonUID.selectedObj)
            createPracticeSlotsForm.value.recomputeStartWeekOptions(createPracticeSlotsForm.value.seasonUID.selectedObj)
          }
        }

        slotSchedulingData.value = SlotSchedulingDataStore(
          authZ_practiceSchedulerSuperUser.value ? "admin" : "self",
          await getPracticeSlotAssignmentData1(axiosInstance, {userID: User.value.userID})
        )

        slotSchedulingData.value.season.seasonUID.selectedKey = forceCheckedIndexedAccess(slotSchedulingData.value.season.seasonOptions.options, 0)?.value || ""
        if (slotSchedulingData.value.season.seasonUID.selectedObj) {
          handleUpdateAssignCoachesSeason(slotSchedulingData.value.season.seasonUID.selectedObj)
        }
      }

      const initReportingForm = (args: {seasons: Season[], fields: Field[]}) : void => {
        reportingData.value = PracticeSlotsAssignmentsReportStore(args)
      }
      const initPracticeSchedulerPermitsMgrStore = (args: {seasons: PracticeSchedulerSeason[]}) : void => {
        practiceSchedulerPermitsMgrStore.value = PracticeSchedulerPermitsMgrStore(args)
      }

      return {
        get createPracticeSlotsForm() { return createPracticeSlotsForm.value },
        initCreatePracticeSlotsFormAndSlotSchedulingData,
        get reportingForm() { return reportingData.value },
        initReportingForm,
        initPracticeSchedulerPermitsMgrStore,
        // TODO: rename to ... "createSlotAssignmentsState"
        get slotSchedulingData() { return requireNonNull(slotSchedulingData.value) },
        get practiceSchedulerPermitsMgrStore() { return practiceSchedulerPermitsMgrStore.value },
        selectedTabID,
      }
    })()

    const {
      px_leftColWidth,
      px_perFieldColWidthMinMax,
      px_cellBorderAndGridlineThickness,
      px_perHourCellHeightMinMax,
      px_perHourCellHeight,
      px_perFieldColWidth,
      gridSlicesPerHour,
      gridSlicesPerHourOptions,
    } = CommonCalendarLayoutData()


    const px_totalTableWidth = computed(() => {
      return px_leftColWidth + (treeMgr.totalFieldRenderCount * px_perFieldColWidth.renderValue)
    })

    /**
     * The hours we intend to draw for the calendar view.
     * We expect this to be contiguous (i.e. no holes).
     */
    const hours = (() => {
      // n.b. this is a single value that should be updated atomically
      const minMaxHr24 = shallowRef({
        min: 8 as Integerlike,
        max: 20 as Integerlike,
      } as const)

      const hrs = computed<number[]>(() => {
        const minDisplayHour = parseIntOrFail(minMaxHr24.value.min)
        const maxDisplayHour = parseIntOrFail(minMaxHr24.value.max)

        const vs : number[] = []

        for (let i = minDisplayHour; i <= maxDisplayHour; i++) {
          vs.push(i)
        }
        return vs;
      })

      return {
        minMaxHr24,
        get hrs() { return hrs.value }
      }
    })()

    const view = (() => {
      const seasonOptions = ref<UiOptions>(noAvailableOptions("Loading..."))
      const fieldOptions = ref<UiOptions>(noAvailableOptions("Loading..."))

      const selectedSeason = shallowRef(MutUiSelection<Guid, PracticeSchedulerSeason | null>(() => null, ""))
      const selectedFields = shallowRef(MutUiSelection<Guid[], Field[]>(() => [], []))

      const dateFrom = ref<Datelike>(dayjs().format(DAYJS_FORMAT_HTML_DATE))
      const dateTo = ref<Datelike>(dayjs().add(1, "week").format(DAYJS_FORMAT_HTML_DATE))
      const onlyShowSelectedDatesAndFieldsHavingContent = ref(true)

      const init = async (args: {seasons: PracticeSchedulerSeason[], competitions: Competition[], fields: Field[]}) : Promise<void> => {
        seasonOptions.value = args.seasons.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: args.seasons.map(v => ({label: v.seasonName, value: v.seasonUID}))}
        fieldOptions.value = args.fields.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: args.fields.map(v => ({label: v.fieldAbbrev, value: v.fieldUID}))}

        selectedSeason.value = MutUiSelection(
          seasonUID => args.seasons.find(v => v.seasonUID === seasonUID) ?? null,
          args.seasons.find(v => v.seasonUID === coreCompSeasonUID.value)?.seasonUID
            || args.seasons.find(v => v.seasonUID === Client.value.instanceConfig.currentseasonuid)?.seasonUID
            || forceCheckedIndexedAccess(args.seasons, 0)?.seasonUID
            || ""
        )

        selectedFields.value = MutUiSelection(
          fieldUIDs => {
            const fs = new Set(fieldUIDs)
            return args.fields.filter(v => fs.has(v.fieldUID))
          },
          args.fields.map(v => v.fieldUID) // all fields initially selected
        )

        const season = selectedSeason.value.selectedObj
        if (season) {
          const v = practiceCentricSeasonCurrentWeek(season)
          dateFrom.value = v.dateFrom
          dateTo.value = v.dateTo
        }
      }

      const viewInfo = computed(() => {
        return {
          seasonUID: selectedSeason.value.selectedKey,
          fieldUIDs: selectedFields.value.selectedKey,
          dateFrom: dayjs(dateFrom.value),
          dateTo: dayjs(dateTo.value),
        } as const
      })

      return {
        init,
        seasonOptions,
        fieldOptions,
        selectedSeason,
        selectedFields,
        dateFrom,
        dateTo,
        onlyShowSelectedDatesAndFieldsHavingContent,
        get viewInfo() {
          return viewInfo.value
        }
      }
    })()

    /**
     * reload the slots based on current view options
     */
    const naiveDefaultFullReload = async (args?: {minHr24?: number, maxHr24?: number}) : Promise<void>  => {
      await treeMgr.reload({
        seasonUID: view.selectedSeason.value.selectedKey,
        dateFrom: view.dateFrom.value,
        dateTo: view.dateTo.value,
        onlyShowSelectedDatesAndFieldsHavingContent: view.onlyShowSelectedDatesAndFieldsHavingContent.value,
        divID: undefined,
        fieldUIDs: view.selectedFields.value.selectedKey,
      })
      fixupViewTimeSpanBasedOnCurrentSlotList(args)
    }

    /**
     * investigate: should auto-happen on treeMgr.reload, or something?
     */
    const fixupViewTimeSpanBasedOnCurrentSlotList = (args?: {minHr24?: number, maxHr24?: number}) => {
      const slots = treeMgr.listView
      const {computedMinHr24, computedMaxHr24} = (() => {
        if (slots.length === 0) {
          return {computedMinHr24: 8, computedMaxHr24: 22}
        }
        else {
          return {
            computedMinHr24: min(slots.map(slot => dayjs(slot.start).hour())),
            // if we compute 9pm, we show 9pm
            computedMaxHr24: max(slots.map(slot => {
              const end = dayjs(slot.end)
              const dayMinute = ((end.hour() * 60) + end.minute()) - 1 // e.g. 9:00pm would be 1260 - 1 == 1259
              return Math.floor(dayMinute / 60) // e.g. (1259 / 60) == 20.98 ... floor(20.98) == 20 ... so the final hour slot to show is 20 (8pm), which would encompass anything from start->9pm
            }))
          }
        }
      })()

      hours.minMaxHr24.value = {
        min: args?.minHr24 ?? computedMinHr24,
        max: args?.maxHr24 ?? computedMaxHr24,
      };
    }

    const getCalendarElementStylesEx = (layoutNode: cal.LayoutNodeRoot<NodeT> | cal.LayoutNode<NodeT>) : CalendarElementStyle | null => {
      // if (!layoutNode.parent) {
      //   // TODO: instead of null, return some default (which we'll never use anyway, because we don't draw root nodes)
      //   return null
      // }

      // const data = layoutNode.data

      // if (data.hasSomeAssignment) {
      //   if (actionState.slotSchedulingData.workingCalendarInfoForSomeTargetUserSeason.slotsAlreadyAssignedForSelectedUser.find(v => v.practiceSlotID === data.practiceSlotID)) {
      //     return calendarElementStylingDefault
      //   }

      //   return {
      //     title: {
      //       backgroundColor: "gray",
      //       color: "white",
      //     },
      //     body: {
      //       backgroundColor: "gray",
      //       color: "white",
      //     }
      //   }
      // }

      return calendarElementStylingDefault
    }

    const doCreatePracticeSlots = async () : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          const form : CreatePracticeSlotsFormStore = requireNonNull(actionState.createPracticeSlotsForm)

          const field = requireNonNull(form.fieldUID.selectedObj)
          const season = requireNonNull(form.seasonUID.selectedObj)

          const generated = await createPracticeSlots(axiosInstance, {
            seasonUID: form.seasonUID.selectedKey,
            fieldUID: form.fieldUID.selectedKey,
            divIDs: form.divIDs.selectedKey,
            slots: ExtractFormSlots(form),
          })

          undoStack.value.push({
            type: "create",
            practiceSlotIDs: generated.map(v => v.practiceSlotID),
            description: `Create ${generated.length} slot${generated.length === 1 ? "" : "s"} for ${season.seasonName} on ${field.fieldAbbrev}`
          })

          const genMessage = generated.length === 1
            ? "Generated 1 practice slot."
            : `Generated ${generated.length} practice slots.`

          iziToast.success({message: genMessage})

          await naiveDefaultFullReload()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      })
    }

    const doCreatePracticeSlotAssignments = async (args: {
      season: Season,
      userInfo: SlotAssignmentUserBinding,
      practiceSlotIDs: number[],
      viewDateFrom: Datelike,
      viewDateTo: Datelike,
      onlyShowFieldsHavingSlots: boolean,
      practiceSlotAssignmentPermitID: number | null
    }) : Promise<void> => {
      // some minor sanity checking here
      // we can check that if there is a provided permit, then there is only a single slot being signed up for,
      // but we cannot check that if there is only a single slot to be signed up for, that there is always a permit,
      // because a "recurring" assignment might potentially be for only a single slot, although that condition will probably
      // be rare
      if (args.practiceSlotAssignmentPermitID !== null) {
        assertTruthy(args.practiceSlotIDs.length === 1)
      }

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          const result = await createPracticeSlotAssignments(axiosAuthBackgroundInstance, {
            //userID: args.userInfo.userlike.userID,
            teamID: args.userInfo.teamInfo.teamID,
            practiceSlotIDs: args.practiceSlotIDs,
            practiceSlotAssignmentPermitID: args.practiceSlotAssignmentPermitID ?? undefined,
          })

          if (result.errorCode) {
            switch (result.errorCode) {
              case "unavailable": {
                const message = args.practiceSlotIDs.length === 1
                  ? "The requested slot is unavailable. Another user may have just recently signed up for it. Refresh your calendar view for an up-to-date listing."
                  : "Some of the requested slots were unavailable. Another user may have just recently signed up for them. Refresh your calendar view for an up-to-date listing."

                iziToast.warning({message})

                return;
              }
              default: exhaustiveCaseGuard(result.errorCode)
            }
          }

          await doLoadSlotsForAssignmentWorkflow({
            season: args.season,
            userInfo: args.userInfo,
            viewDateFrom: args.viewDateFrom,
            viewDateTo: args.viewDateTo,
            onlyShowFieldsHavingSlots: args.onlyShowFieldsHavingSlots,
            tryRetainScrollPos: true,
          })
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      })
    }

    const doDeletePracticeSlotAssignment = async (args: {
      season: Season,
      userInfo: SlotAssignmentUserBinding,
      assignment: PracticeSlotAssignment,
      viewDateFrom: Datelike,
      viewDateTo: Datelike,
      onlyShowFieldsHavingSlots: boolean,
      deleteAllGroupMembers: boolean,
      generateLooseSignupPermit: boolean,
      permitComment: string,
    }) : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          await deletePracticeSlotAssignment(axiosAuthBackgroundInstance, {
            practiceSlotAssignmentID: args.assignment.practiceSlotAssignmentID,
            deleteAllGroupMembers: args.deleteAllGroupMembers,
            generateLooseSignupPermit: args.generateLooseSignupPermit,
            permitComment: args.permitComment,
          })

          await doLoadSlotsForAssignmentWorkflow({
            userInfo: args.userInfo,
            season: args.season,
            viewDateFrom: args.viewDateFrom,
            viewDateTo: args.viewDateTo,
            onlyShowFieldsHavingSlots: args.onlyShowFieldsHavingSlots,
            tryRetainScrollPos: true,
          })
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      })
    }

    const confirmPracticeSlotAssignmentSignupModalController = (() => {
      type Data = {
        mode: "self" | "admin",
        slot: PracticeSlotEx,
        userInfo: SlotAssignmentUserBinding,
        viewDateFrom: Datelike,
        viewDateTo: Datelike,
        onlyShowFieldsHavingSlots: boolean,
        hasExistingRecurringSeasonalSelection: boolean,
        availableOneOffPermits: {practiceSlotAssignmentPermitID: number}[],
      }

      const {busy, onCloseCB} = useDefaultNoCloseModalIfBusy()

      const submit = async (args: {
        season: Season,
        userInfo: SlotAssignmentUserBinding,
        practiceSlotIDs: number[],
        viewDateFrom: Datelike,
        viewDateTo: Datelike,
        onlyShowFieldsHavingSlots: boolean,
        practiceSlotAssignmentPermitID: number | null,
      }) => {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          try {
            try {
              busy.value = true
              await doCreatePracticeSlotAssignments(args)
            }
            finally {
              busy.value = false
            }
            confirmPracticeSlotAssignmentSignupModalController.close()
          }
          catch (err) {
            AxiosErrorWrapper.rethrowIfNotAxiosError(err)
          }
        })
      }

      return DefaultModalController_r<Data>({
        title: () => <>
          <div>Confirm Assignment</div>
          <div class="border-b my-2"/>
        </>,
        content: data => {
          if (!data) {
            return null
          }
          return <div>
            <ConfirmPracticeSlotAssignmentsModal
              mode={data.mode}
              slot={data.slot}
              teamInfo={data.userInfo.teamInfo}
              hasExistingRecurringSeasonalSelection={data.hasExistingRecurringSeasonalSelection}
              availableOneOffPermits={data.availableOneOffPermits}
              onCancel={() => confirmPracticeSlotAssignmentSignupModalController.close()}
              onCommit={args => submit({
                season: data.slot.season,
                userInfo: data.userInfo,
                practiceSlotIDs: args.practiceSlotIDs,
                viewDateFrom: data.viewDateFrom,
                viewDateTo: data.viewDateTo,
                onlyShowFieldsHavingSlots: data.onlyShowFieldsHavingSlots,
                practiceSlotAssignmentPermitID: args.practiceSlotAssignmentPermitID,
              })}
            />
            <DefaultTinySoccerballBusyOverlay if={busy.value}/>
          </div>
        }
      }, {onCloseCB})
    })()

    const confirmDeleteModalController = (() => {
      type Data = {
        ctx: SlotSchedulingDataStore,
        slot: PracticeSlotEx,
        assignment: PracticeSlotAssignment,
        viewDateFrom: Datelike,
        viewDateTo: Datelike,
        onlyShowFieldsHavingSlots: boolean,
      }

      const {busy, onCloseCB} = useDefaultNoCloseModalIfBusy()

      const submit = async (args: {
        season: Season,
        userInfo: SlotAssignmentUserBinding,
        assignment: PracticeSlotAssignment,
        deleteAllGroupMembers: boolean,
        generateLooseSignupPermit: boolean,
        viewDateFrom: Datelike,
        viewDateTo: Datelike,
        onlyShowFieldsHavingSlots: boolean,
        permitComment: string,
      }) => {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          try {
            try {
              busy.value = true
              await doDeletePracticeSlotAssignment(args)
            }
            finally {
              busy.value = false
            }
            confirmDeleteModalController.close()
          }
          catch (err) {
            AxiosErrorWrapper.rethrowIfNotAxiosError(err)
          }
        })
      }

      return DefaultModalController_r<Data>({
        title: () => <>
          <div>Remove Assignment</div>
          <div class="border-b my-2"/>
        </>,
        content: data => {
          if (!data) {
            return null
          }

          return <div>
            <ConfirmPracticeSlotAssignmentDeleteModal
              mode={data.ctx.mode}
              slot={data.slot}
              assignment={data.assignment}
              onCancel={() => confirmDeleteModalController.close()}
              onCommit={args => submit({
                season: data.slot.season,
                userInfo: practiceSlotAssignmentToUserBinding(data.assignment),
                assignment: data.assignment,
                viewDateFrom: data.viewDateFrom,
                viewDateTo: data.viewDateTo,
                onlyShowFieldsHavingSlots: data.onlyShowFieldsHavingSlots,
                deleteAllGroupMembers: args.deleteAllGroupMembers,
                generateLooseSignupPermit: args.generateLooseSignupPermit,
                permitComment: args.permitComment,
              })}
            />
            <DefaultTinySoccerballBusyOverlay if={busy.value}/>
          </div>
        }
      }, {onCloseCB})
    })()

    const adminSlotModalController = (() => {
      const {busy, onCloseCB} = useDefaultNoCloseModalIfBusy()

      /**
       * absolutely brutal -- we reload the whole calendar, because unlinking might affect the whole tree (well, will it? ... we could maybe be more targeted)
       * reloading the whole tree is not an in-place operation, it produces new everything. So, the slot obj we have upon modal-open is no longer the calendar's up-to-date
       * slot obj. So we force-set the modal to have the new slot arg. It would be much nicer to just have the slot being passed into the modal
       * naturally update but that's not how it works at this time. Basically, our modal has an out-of-date snapshot here until we {force-set, "re-open"} it.
       * Also note that {force-setting, "re-opening"} the modal should visually be a no-op because the modal is already open, so there is no animation to perform, it just serves
       * as the way to force-update the args passed to the modal.
       */
      const __fixme__reload_wthKludge = async (data: PracticeSchedulerAdminModalEvent) => {
        await naiveDefaultFullReload()
        const updatedSlot = requireNonNull(treeMgr.findNodeByPracticeSlotID(data.slot.practiceSlotID)) // we gotta find it! a failure case would be someone other than this browser tab deleted it
        adminSlotModalController.forceSetData({...data, slot: updatedSlot})
      }

      const doUnlink = async (args: PracticeSchedulerAdminModalEvent) => {
        try {
          try {
            busy.value = true
            await withMaybeTryRetainScrollPos(
              true,
              async () : Promise<void> => {
                assertNonNull(args.slot.practiceSlotGroupID)
                await unlinkPracticeSlot(axiosAuthBackgroundInstance, {practiceSlotID: args.slot.practiceSlotID, practiceSlotGroupID: args.slot.practiceSlotGroupID})
                await __fixme__reload_wthKludge(args)
              }
            )
          }
          finally {
            busy.value = false
          }

          // n.b. we don't close the modal here
          madeSomeChangePotentiallyInvalidatingAssignOrDeleteValidity.value = true
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }

      const doLink = async (args: {
        evt: PracticeSchedulerAdminModalEvent,
        practiceSlotGroupID: number,
      }) => {
        try {
          try {
            busy.value = true
            await withMaybeTryRetainScrollPos(
              true,
              async () : Promise<void> => {
                assertTruthy(!args.evt.slot.practiceSlotGroupID) // does NOT currently have an associated group
                await linkPracticeSlot(axiosAuthBackgroundInstance, {practiceSlotID: args.evt.slot.practiceSlotID, practiceSlotGroupID: args.practiceSlotGroupID})
                await __fixme__reload_wthKludge(args.evt)
              }
            )
          }
          finally {
            busy.value = false
          }

          // n.b. we don't close the modal here
          madeSomeChangePotentiallyInvalidatingAssignOrDeleteValidity.value = true
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }

      const doUpdateDivisions = async (args: {
        data: PracticeSchedulerAdminModalEvent,
        newDivIdList: Guid[],
      }) => {
        try {
          try {
            busy.value = true
            await withMaybeTryRetainScrollPos(
              true,
              async () : Promise<void> => {
                await updatePracticeSlotDivisions(axiosAuthBackgroundInstance, {
                  practiceSlotID: args.data.slot.practiceSlotID,
                  practiceSlotGroupID: args.data.slot.practiceSlotGroupID ?? undefined,
                  divIDs: args.newDivIdList
                })
                await __fixme__reload_wthKludge(args.data)
              }
            )
          }
          finally {
            busy.value = false
          }

          // n.b. we don't close the modal here
          madeSomeChangePotentiallyInvalidatingAssignOrDeleteValidity.value = true
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }

      const doDeleteSlot = async (args: {
        practiceSlotIDs: number[],
      }) => {
        try {
          try {
            busy.value = true
            await withMaybeTryRetainScrollPos(
              true,
              async () : Promise<void> => {
                await deletePracticeSlots(axiosAuthBackgroundInstance, {practiceSlotIDs: args.practiceSlotIDs})
                await naiveDefaultFullReload()
              }
            )
          }
          finally {
            busy.value = false
          }

          madeSomeChangePotentiallyInvalidatingAssignOrDeleteValidity.value = true // well, we close the modal right after this, but if we didn't close it this would be the right thing to do
          adminSlotModalController.close()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }

      const doConfirmSlotAssignments = async (args: {
        season: Season,
        userInfo: SlotAssignmentUserBinding,
        practiceSlotIDs: number[],
        viewDateFrom: Datelike,
        viewDateTo: Datelike,
        onlyShowFieldsHavingSlots: boolean,
        practiceSlotAssignmentPermitID: number | null,
      }) => {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          try {
            try {
              busy.value = true
              await doCreatePracticeSlotAssignments(args)
            }
            finally {
              busy.value = false
            }
            adminSlotModalController.close()
          }
          catch (err) {
            AxiosErrorWrapper.rethrowIfNotAxiosError(err)
          }
        })
      }

      const doConfirmSlotAssignmentDeletions = async (args: {
        season: Season,
        userInfo: SlotAssignmentUserBinding,
        assignment: PracticeSlotAssignment,
        deleteAllGroupMembers: boolean,
        generateLooseSignupPermit: boolean,
        viewDateFrom: Datelike,
        viewDateTo: Datelike,
        onlyShowFieldsHavingSlots: boolean,
        permitComment: string,
      }) => {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          try {
            try {
              busy.value = true
              await doDeletePracticeSlotAssignment(args)
            }
            finally {
              busy.value = false
            }
            adminSlotModalController.close()
          }
          catch (err) {
            AxiosErrorWrapper.rethrowIfNotAxiosError(err)
          }
        })
      }

      const doUpdateSlot = async (args: {
        event: PracticeSchedulerAdminModalEvent,
        allowableTeamCount: number,
        visibleOnOrAfter: "" | Datelike,
      }) => {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          try {
            try {
              busy.value = true
              await updatePracticeSlot(axiosAuthBackgroundInstance, {
                practiceSlotID: args.event.slot.practiceSlotID,
                practiceSlotGroupID: args.event.slot.practiceSlotGroupID,
                allowableTeamCount: args.allowableTeamCount,
                visibleOnOrAfter: args.visibleOnOrAfter,
              })
            }
            finally {
              busy.value = false
            }

            madeSomeChangePotentiallyInvalidatingAssignOrDeleteValidity.value = true
            await __fixme__reload_wthKludge(args.event)
          }
          catch (err) {
            AxiosErrorWrapper.rethrowIfNotAxiosError(err)
          }
        })
      }

      const madeSomeChangePotentiallyInvalidatingAssignOrDeleteValidity = ref(false)
      const onOpenCB = () => {
        madeSomeChangePotentiallyInvalidatingAssignOrDeleteValidity.value = false
      }

      return DefaultModalController_r<PracticeSchedulerAdminModalEvent>({
        title: () => <>
          <div>Practice Slot Details</div>
          <div class="border-b my-2"/>
        </>,
        content: data => {
          if (!data) {
            return null
          }

          return <div>
            <PracticeSlotDetailModal
              slot={data.slot}
              maybeConfirmAssignment={data.maybeConfirmAssignment}
              maybeConfirmDelete={data.maybeConfirmDelete}
              madeSomeChangePotentiallyInvalidatingAssignOrDeleteValidity={madeSomeChangePotentiallyInvalidatingAssignOrDeleteValidity.value}
              onUnlink={() => doUnlink(data)}
              onLink={args => doLink({evt: data, practiceSlotGroupID: args.practiceSlotGroupID})}
              onUpdateDivisions={args => doUpdateDivisions({data, newDivIdList: args.newDivIdList})}
              onDelete={args => doDeleteSlot({practiceSlotIDs: args.practiceSlotIDs})}
              onCancel={() => adminSlotModalController.close()}
              onConfirmAssignmentSignup={args => doConfirmSlotAssignments({
                season: data.slot.season,
                userInfo: requireNonNull(data.maybeConfirmAssignment).userInfo, // should only get this event if this was non-null
                practiceSlotIDs: args.practiceSlotIDs,
                practiceSlotAssignmentPermitID: args.practiceSlotAssignmentPermitID,
                viewDateFrom: view.dateFrom.value, // why are we looping this through?
                viewDateTo: view.dateTo.value, // why are we looping this through?
                onlyShowFieldsHavingSlots: view.onlyShowSelectedDatesAndFieldsHavingContent.value, // why are we looping this through?
              })}
              onConfirmAssignmentDelete={args => {
                // definitely non-null -- requires that this was non-null in order for the elem to emit this event.
                const assignment = requireNonNull(data.maybeConfirmDelete).assignment
                doConfirmSlotAssignmentDeletions({
                  season: data.slot.season,
                  userInfo: practiceSlotAssignmentToUserBinding(assignment),
                  assignment,
                  deleteAllGroupMembers: args.deleteAllGroupMembers,
                  generateLooseSignupPermit: args.generateLooseSignupPermit,
                  permitComment: args.permitComment,
                  viewDateFrom: view.dateFrom.value, // why are we looping this through?
                  viewDateTo: view.dateTo.value, // why are we looping this through?
                  onlyShowFieldsHavingSlots: view.onlyShowSelectedDatesAndFieldsHavingContent.value, // why are we looping this through?
                })
              }}
              onUpdateSlot={args => doUpdateSlot({
                event: data,
                allowableTeamCount: args.allowableTeamCount,
                visibleOnOrAfter: args.visibleOnOrAfter,
              })}
            />
            <DefaultTinySoccerballBusyOverlay if={busy.value}/>
          </div>
        }
      }, {onCloseCB, onOpenCB})
    })()

    /**
     * user is null if we are in admin mode and we do not have a focused user yet
     */
    const doLoadSlotsForAssignmentWorkflow = async (args: {
      season: Season,
      userInfo: null | SlotAssignmentUserBinding,
      viewDateFrom: Datelike,
      viewDateTo: Datelike,
      onlyShowFieldsHavingSlots: boolean,
      tryRetainScrollPos: boolean,
    }) : Promise<void> => {
      if (args.userInfo === null) {
        assertTruthy(manageMode.value === "admin")
      }

      try {
        await withMaybeTryRetainScrollPos(
          args.tryRetainScrollPos,
          async () : Promise<void> => {
            const dateFrom = dayjs(args.viewDateFrom).format(DAYJS_FORMAT_HTML_DATE)
            const dateTo = dayjs(args.viewDateTo).format(DAYJS_FORMAT_HTML_DATE)

            view.dateFrom.value = dateFrom
            view.dateTo.value = dateTo
            view.onlyShowSelectedDatesAndFieldsHavingContent.value = args.onlyShowFieldsHavingSlots

            // if we have user/team info here, we need to load calendar info specific to for that user/team.
            // Otherwise we clear out the existing user/team info.
            if (args.userInfo) {
              await actionState.slotSchedulingData.workingCalendarInfoForSomeTargetUserSeason.refresh({seasonUID: args.season.seasonUID})
            }
            else {
              actionState.slotSchedulingData.workingCalendarInfoForSomeTargetUserSeason.reset()
            }

            // reload all the slots
            await treeMgr.reload({
              seasonUID: args.season.seasonUID,
              dateFrom,
              dateTo,
              onlyShowSelectedDatesAndFieldsHavingContent: args.onlyShowFieldsHavingSlots,
              divID: args.userInfo?.teamInfo.division.divID,
              fieldUIDs: view.selectedFields.value.selectedKey,
            });

            fixupViewTimeSpanBasedOnCurrentSlotList()
          }
        )
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const withMaybeTryRetainScrollPos = async <T,>(tryRetainScrollPos: boolean, f: () => Promise<T>) : Promise<T> => {
      const savedScrollPos = copyViaJsonRoundTrip(routeRootScrollPos.value)
      const result = await f()

      if (tryRetainScrollPos) {
        await nextTick()
        routeRootScrollContainerRef.value?.scroll({left: savedScrollPos.left, top: savedScrollPos.top})
      }

      return result;
    }

    onMounted(async () => {
      GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        allSeasons.value = await getSeasonsForPracticeScheduler(axiosInstance)
        allCompetitions.value = await getCompetitionsOrFail().then(v => [...v.value]) // spread to drop readonly
        allFields.value = await Client.loadFields(axiosInstance)
        allDivisions.value = await Client.loadDivisions(axiosInstance)

        view.init({
          seasons: authZ_practiceSchedulerSuperUser.value
            ? allSeasons.value // super users get all the seasons
            : (() => {
              const coachSeasonUIDs = new Set(User.userData?.coachAssignmentsMemento.map(v => v.seasonUID) ?? [])
              return allSeasons.value.filter(v => coachSeasonUIDs.has(v.seasonUID)) // non-super-users are only offered the seasons for which they have coach assignments
            })(),
          competitions: allCompetitions.value,
          fields: allFields.value
        })

        await actionState.initCreatePracticeSlotsFormAndSlotSchedulingData({initialSeasonUID: view.selectedSeason.value.selectedKey})
        await actionState.initReportingForm({seasons: allSeasons.value, fields: allFields.value})
        await actionState.initPracticeSchedulerPermitsMgrStore({seasons: allSeasons.value})

        if (authZ_practiceSchedulerSuperUser.value) {
          actionState.selectedTabID.value = PracticeSchedulerActionTabID.createPracticeSlots
        }
        else {
          actionState.selectedTabID.value = PracticeSchedulerActionTabID.assignSlots
        }

        handleChangeTab(actionState.selectedTabID.value)

        ready.value = true
      })
    })

    const handleChangeTab = (tabID: PracticeSchedulerActionTabID) => {
      debugger
      actionState.slotSchedulingData.workingCalendarInfoForSomeTargetUserSeason.reset()

      if (tabID === "createPracticeSlots") {
        // TODO: reinit tree from create slot form
      }
      else if (tabID === "assignPracticeSlots") {
        treeMgr.reset()
        tryLoadSlotsForAssignmentWorkflow()
      }
      else if (tabID === "reporting") {
        treeMgr.reset()
      }
      else if (tabID === "permitsMgr") {
        treeMgr.reset()
      }
      else {
        exhaustiveCaseGuard(tabID)
      }
      actionState.selectedTabID.value = tabID
    }

    const handleUpdateAssignCoachesSeason = (season: PracticeSchedulerSeason) : void => {
      assignCoachesStore.updateDatesFromSeason(season)

      const form = actionState.slotSchedulingData

      form.user.userOptions.reset()
      form.user.reset()

      // ???? is user going to remain valid here if we change season out from under ourselves
      // ???? didn't we just clear the selectedUser
      form.team.tryInitSelectedUserSeasonInfoIfUserAndSeasonAreSelected()
      form.workingCalendarInfoForSomeTargetUserSeason.reset()
      if (actionState.selectedTabID.value === PracticeSchedulerActionTabID.assignSlots) {
        tryLoadSlotsForAssignmentWorkflow()
      }
    }

    const tryLoadSlotsForAssignmentWorkflow = async () : Promise<void> => {
      const form = actionState.slotSchedulingData

      if (form.team.value.underlying.status === "idle") {
        // no data available, can't do anything meaningful here
        return;
      }

      const userSeason = await form.team.value.getResolvedOrFail()
      if (!userSeason) {
        if (manageMode.value === "self") {
          // in self mode, can't do anything without having init'd selectedUserSeasonInfo
          return
        }
      }

      const season = form.season.seasonUID.selectedObj

      if (!season) {
        return // shouldn't happen
      }

      const userinfo : {ok : false} | {ok: true, user: null | SlotAssignmentUserBinding} = (() => {
        const userlike = form.user.selectedUser.userlike
        const teamInfo = userSeason?.teamID.selectedObj

        if (!userlike || !teamInfo) {
          if (actionState.slotSchedulingData.mode === "self") {
            // in self mode, need to have selected a user (well, that's implied...) and a team
            return {ok: false} as const
          }
          else if (actionState.slotSchedulingData.mode === "admin") {
            // in admin mode, we might not have selected a user/team yet, and that's ok
            return {ok: true, user: null} as const
          }
          else {
            exhaustiveCaseGuard(actionState.slotSchedulingData.mode)
          }
        }
        else {
          return {ok: true, user: {userlike, teamInfo: teamInfo.team}} as const
        }
      })()

      if (!userinfo.ok) {
        return
      }

      const {dateFrom, dateTo} = assignCoachesStore.effectiveDateFromTo(season) // harumph shouldn't it know its own season

      doLoadSlotsForAssignmentWorkflow({
        season,
        userInfo: userinfo.user,
        viewDateFrom: dateFrom,
        viewDateTo: dateTo,
        onlyShowFieldsHavingSlots: view.onlyShowSelectedDatesAndFieldsHavingContent.value, // uh why loop this through
        tryRetainScrollPos: false
      })
    }

    const rootRef = ref<HTMLElement | null>(null)

    // cruft to satisfy calendar prop requirements; but it should not be a calendar requirement, and is effectively unused
    const __fixme__view_selectedCompetitionUIDs = ref(new Set<string>())
    const __fixme__view_selectedDivIDs = ref(new Set<string>())

    return () => {
      if (!ready.value) {
        return null
      }

      return <div style={`--fk-margin-outer: none; position:relative; z-index:0; width: max(100%, ${px_totalTableWidth.value}px);`} ref={rootRef}>
        <AutoModal controller={confirmDeleteModalController} data-test="cancelPracticeSlotAssignmentModal"/>
        <AutoModal controller={confirmPracticeSlotAssignmentSignupModalController} data-test="confirmPracticeSlotAssignmentModal"/>
        <AutoModal controller={adminSlotModalController} data-test="editSlotModal" class="max-w-3xl"/>
        <SchedulerControlsElement
          routeRootRef={rootRef.value}
          class="my-2"
        >
          {{
            default: () => <>
              <div style="grid-column: -1/1;">
                <h2>Practice Scheduler</h2>
                <h4>Practice Scheduling shares the idea of a <i>field slot</i> with the game scheduler, but a practice slot is almost always created on a recurring basis; fields may have more than one practice slot; and practice slots are made available to head and co-coaches to select for their team(s). See <a class="underline" target="_blank" href="https://gitlab.inleague.io/content/guides-and-documents/-/wikis/Practice-Scheduling">the community guide</a> for details.</h4>
              </div>
              {manageMode.value === "admin"
                ?<div class="bg-white rounded-md shadow-md border rounded-md h-full">
                  <div class="px-2 py-1 bg-green-800 text-white rounded-t-md">General View Options</div>
                  <div class="p-2" style="--fk-padding-input: .35em;">
                    <PracticeSchedulerViewOptions
                      data-test="PracticeSchedulerViewOptions"
                      seasonOptions={view.seasonOptions.value}
                      fieldOptions={view.fieldOptions.value}
                      selectedSeason={view.selectedSeason.value}
                      selectedField={view.selectedFields.value}
                      dateFrom={view.dateFrom}
                      dateTo={view.dateTo}
                      minMaxHr24={hours.minMaxHr24.value}
                      onlyShowSelectedDatesAndFieldsHavingContent={view.onlyShowSelectedDatesAndFieldsHavingContent}
                      onRefresh={() => {
                        naiveDefaultFullReload()
                      }}
                    />
                  </div>
                </div>
                : null}
              <div class="p-2 bg-white rounded-md shadow-md border rounded-md h-full">
                <PracticeSchedulerActions
                  data-test="PracticeSchedulerActions"
                  class="bg-white"
                  mode={manageMode.value}
                  assignCoachesStore={assignCoachesStore}
                  createPracticeSlotsForm={actionState.createPracticeSlotsForm}
                  practiceSlotsAssignmentData={actionState.slotSchedulingData}
                  practiceSchedulerPermitsMgrStore={actionState.practiceSchedulerPermitsMgrStore}
                  reportingStore={actionState.reportingForm}
                  selectedTabId={actionState.selectedTabID.value}
                  viewInfo={view.viewInfo}
                  onChangeTab={tabID => {
                    handleChangeTab(tabID)
                  }}
                  canMakeNonSelfSlotAssignments={authZ_practiceSchedulerSuperUser.value}
                  onCreatePracticeSlots={doCreatePracticeSlots}
                  onUpdate:assignCoachesSeason={season => {
                    handleUpdateAssignCoachesSeason(season)
                  }}
                  onTryLoadSlotsForAssignmentWorkflow={() => {
                    tryLoadSlotsForAssignmentWorkflow()
                  }}
                />
              </div>
            </>

          } satisfies SchedulerControlsSlots}
        </SchedulerControlsElement>

        {undoStack.value.length > 0
          ? <div class="flex items-center gap-2">
            <Btn2
              class="px-2 py-1 flex items-center gap-2"
              onClick={() => popAndRunMostRecentUndoable()}
              data-test="undo-button"
            >
              <div><FontAwesomeIcon icon={faUndo}/></div>
              <div>Undo last action</div>
            </Btn2>
            {undoStack.value[undoStack.value.length - 1].description}
          </div>
          : null}
        {treeMgr.slotResolver.status === "idle"
          ? null
          : treeMgr.slotResolver.status === "pending"
          ? <div class="flex gap-2 items-center"><SoccerBall/>Loading...</div>
          : treeMgr.slotResolver.status === "error"
          ? <div>Sorry, something went wrong.</div>
          : treeMgr.isEmpty
            ? <div data-test="nothingFound">No practice slots found for the current view options.</div>
            : <RootCalendarLayoutElement
            class="mt-4"
            style={`display:inline-block; padding-right:1.5em;`}
            data-test="calendarRoot"
            data-test-schedulingTeamID={
              // this helps sync up tests with the selections ... we can draw the calendar but the selected team resolves asynchronously
              // seems like a bit of a hack to need to do this but the alternative seems to be to not draw the calendar until we get all the info we need
              actionState.slotSchedulingData.team.value.underlying.getOrNull()?.teamID.selectedKey ?? null
            }
            forceRenderKey={treeMgr.__vueKey}
            px_totalTableWidth={px_totalTableWidth.value}
            px_cellBorderAndGridlineThickness={px_cellBorderAndGridlineThickness}
            px_leftColWidth={px_leftColWidth}
            px_perFieldColWidth={px_perFieldColWidth.renderValue}
            px_perHourCellHeight={px_perHourCellHeight.renderValue}
            hours={hours.hrs}
            gridSlicesPerHour={parseIntOrFail(gridSlicesPerHour.value)}
            focusOnBracketGames={false}
            byDateByField={treeMgr.byDateByField}
            dateFieldColDropTargetFactory={null}
            tableRootDropTarget={null}
          >
            {{
              // TODO: see notes on slots/header2 typedef can we make this shared? Probably could if the "node tree map" used Fields as keys instead of fieldUIDs
              header2: () => {
                return <div class="w-full flex row">
                  <div class="flex p-1 bg-white justify-center cell items-center" style={`width: ${px_leftColWidth}px;`}>Field</div>
                  {
                    [...treeMgr.byDateByField.entries()].map(([_, nodesForFieldsByFieldUID]) => {
                      return [...nodesForFieldsByFieldUID.keys()].flatMap((fieldUID) => {
                        // TODO: store Field objects as keys in the map instead of just fieldUID
                        const field = arrayFindOrFail(allFields.value, v => v.fieldUID === fieldUID)
                        return <div class="inline-block cell" style={`width: ${px_perFieldColWidth.renderValue}px;`}>
                          <div style="display:flex;">
                            <div style={`flex-grow: 1;`} class={`p-1 bg-white text-center`}>{field?.fieldName}</div>
                          </div>
                        </div>
                      })
                    })
                  }
                </div>
              },
              renderLayoutNodeRoot: ({date, fieldUID, layoutNodeRoot}) => {
              return <CalendarGridElement
                date={date}
                layoutStrategy={treeMgr.layoutStrategy.getOrFail(date, fieldUID)}
                fieldUID={fieldUID}
                layoutNode={layoutNodeRoot}
                px_containerHeight={(px_perHourCellHeight.renderValue * hours.hrs.length) + (px_cellBorderAndGridlineThickness * hours.hrs.length)}
                px_containerWidth={px_perFieldColWidth.renderValue}
                px_xOffset={0}
                px_cellBorderAndGridlineThickness={px_cellBorderAndGridlineThickness}
                startHour24Inc={hours.hrs[0]}
                endHour24Inc={hours.hrs[hours.hrs.length - 1]}
                focusOnBracketGames={false}
                px_heightPerHour={px_perHourCellHeight.renderValue}
                allowDragOps={false}
                // offset needed to not ride along the right border
                px_elemWidth={px_perFieldColWidth.renderValue - 1}
                px_laneWidth={px_perFieldColWidth.renderValue - 1}
                elemVerticalResizer={null} // elemVerticalResizer}
                elemMover={null} // elemMover}
                z={1}
                moveeNode={null}//elemMover.maybeGetMovee({date, fieldUID: fieldUID})}
                gridSlicesPerHour={parseIntOrFail(gridSlicesPerHour.value)}
                getCalendarElementStyles={getCalendarElementStylesEx}
                isInBulkSelectMode={false}
                selectedCompetitionUIDs={__fixme__view_selectedCompetitionUIDs.value}
                selectedDivIDs={__fixme__view_selectedDivIDs.value}
                // onClick={({layoutNode}) => {
                //   const node = layoutNode as cal.LayoutNode<NodeT>
                //   handleNodeClick(node)
                // }}
                // onShowConfirmDeleteModal={layoutNode => {
                //   //confirmDeleteModalController.open(layoutNode)
                // }}
                isNonInteractiveLayoutNode={() => false}
                canDragOrResizeNode={() => false}
                authZ_canEditNodeViaOverlay={() => false}
              >
                {{
                  body: ({layoutNode: node, elementStyle, nonInteractive}) => {
                    return <PracticeSchedulerCalendarBodyElement
                      elementStyle={elementStyle?.body}
                      isPracticeSchedulerSuperUser={authZ_practiceSchedulerSuperUser.value}
                      node={node}
                      manageMode={manageMode.value}
                      slotSchedulingData={actionState.slotSchedulingData}
                      onSignup={args => confirmPracticeSlotAssignmentSignupModalController.open({
                        ...args,
                        viewDateFrom: view.dateFrom.value,
                        viewDateTo: view.dateTo.value,
                        onlyShowFieldsHavingSlots: view.onlyShowSelectedDatesAndFieldsHavingContent.value,
                      })}
                      onConfirmDelete={args => confirmDeleteModalController.open({
                        ...args,
                        viewDateFrom: view.dateFrom.value,
                        viewDateTo: view.dateTo.value,
                        onlyShowFieldsHavingSlots: view.onlyShowSelectedDatesAndFieldsHavingContent.value,
                      })}
                      onAdminModal={args => adminSlotModalController.open(args)}
                      tree={treeMgr.byDateByField}
                    />
                  },
                } satisfies CalendarGridElementSlots<NodeT>}
              </CalendarGridElement>
            }} satisfies RootCalendarLayoutElementSlots<GameCalendarUiElement>}
          </RootCalendarLayoutElement>
        }
      </div>
    }
  }
})

type UndoableCreate = {
  type: "create",
  practiceSlotIDs: number[],
  description: string,
}

type Undoable = UndoableCreate

function practiceSlotAssignmentToUserBinding(assignment: PracticeSlotAssignment) : SlotAssignmentUserBinding {
  return {
    teamInfo: assignment.team
  }
}
